import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import ModularPage from "../templates/ModularPage";
import { termsPage } from "../types";

export const IsoPage = ({
  data: {
    data: { iso },
  },
}) => {
  const { title, description, content, seoMetaTags } = iso;

  return (
    <ModularPage
      title={title}
      description={description}
      content={content}
      seoMetaTags={seoMetaTags}
    />
  );
};

IsoPage.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.shape({
      iso: PropTypes.shape(termsPage).isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  query getIso {
    data: dato {
      iso {
        seoMetaTags: _seoMetaTags {
          tag
          content
          attributes
        }
        title
        description
        content {
          ... on DatoCMS_BasicTitleDescriptionRecord {
            __typename
            id
            title
            description
            largeDescription
          }
          ... on DatoCMS_ImageRecord {
            __typename
            id
            source {
              url
            }
          }
          ... on DatoCMS_TextColumnRecord {
            __typename
            id
            title
            descriptionRight
            descriptionLeft
          }
        }
      }
    }
  }
`;

export default IsoPage;
